import React, { useState } from "react";
import { Container, Row, Col, ButtonGroup, Button } from "react-bootstrap";
import pig from "../../../icons/icon/pig.svg";
import money from "../../../icons/icon/pay-per-use.svg";
import world from "../../../icons/icon/world.svg";
import plug from "../../../icons/icon/plug.svg";
import forest from "../../../icons/forest.svg";
import { useSelector, useDispatch } from "react-redux";
import { set, get } from "../../../reducers/data";
import chevronRight from "../../../icons/icon/chevron_right.svg";

function BoxInfo({ title, icon, children, subtitle, disclaimer }) {
  return (
    <>
      <Col className="box col-12">
        <div className="h-100 d-flex flex-column">
          <div className="box-content">
            <div className="d-flex box-top align-items-center col-12 col-lg-6">
              <div className="icon-box">
                <img src={icon} alt={""} role="presentation" />
              </div>
              <div>
                <div className="box-info mb-1">{title}</div>
                <div
                  className="sub f14 lh150"
                  dangerouslySetInnerHTML={{ __html: subtitle }}
                ></div>
              </div>
            </div>
            <div className="box-middle col-12 col-lg-6">{children}</div>
          </div>
        </div>
      </Col>
      {disclaimer && <div className="disclaimer">{disclaimer}</div>}
    </>
  );
}

function BlueboxComponent({ title, title1, num1, title2, num2, children }) {
  return (
    <div className="bluebox row no-gutters">
      <div className="left col-12 col-lg-6">
        <div className="btitle" style={{ color: "#131416" }}>
          {title}
        </div>
        <div className="bsubtitle">{children}</div>
      </div>
      <div className="d-flex justify-content-between right col-12 col-lg-6">
        <div className="subl">
          <div className="tit">{title1}</div>
          <div className="number">{num1}</div>
        </div>
        <div className="subr">
          <div className="tit">{title2}</div>
          <div className="number">{num2}</div>
        </div>
      </div>
    </div>
  );
}

function BeneficiFinaliComponent() {
  const data = useSelector(get);
  const Induzione =
    data?.products?.length === 1 &&
    data?.products?.find((p) => p.name === "Induzione") !== undefined;
  let risparmioAnnuo = "N/A";

  if (data["RisparmioAnnuo"])
    risparmioAnnuo = parseFloat(data["RisparmioAnnuo"])
      .toLocaleString()
      .replaceAll(",", ".");
  return (
    <Container fluid className="benefici">
      <BlueboxComponent
        title="Stima dell'impegno economico"
        title1="Investimento"
        num1={data["Investimento"]}
        title2="Stima del tempo di recupero dell’investimento"
        num2={
          data["RecuperoTotale"] !== "Nessun acquisto selezionato" ||
          data["RecuperoTotale"] === null
            ? data["RecuperoTotale"] + " anni"
            : "Nessun acquisto selezionato"
        }
      >
        Impegno economico necessario per{" "}
        <b>implementare le soluzioni scelte.</b>
        <br />
        <br />
        Per i <b>prodotti Samsung</b>, il finanziamento è l'unica modalità di
        acquisto disponibile, mentre per gli altri prodotti non Samsung è
        possibile scegliere fra il pagamento in un’unica soluzione e la modalità
        di finanziamento.
      </BlueboxComponent>
      <p className="gen-subtitle mt-2">
        L’importo dell’investimento rappresenta <b>il totale dei costi che dovrai
        sostenere per le nuove soluzioni</b> che hai scelto per la tua casa.
        Investire in tali nuove soluzioni può permetterti di risparmiare sulle
        spese energetiche. Ti abbiamo quindi indicato anche una stima (a parità
        di consumi) del tempo necessario per <b>recuperare l’investimento iniziale</b>,
        ossia quando i risparmi raggiungeranno i costi sostenuti.
      </p>

      <div className="f20 bold mt-5">
        Ti stai chiedendo "Quali sono i benefici"?
      </div>
      <div className="small-text mb-4">
        Eccoli qui! Se decidi di confermare questa soluzione otterrai i seguenti
        benefici:
      </div>

      <p className="blue-box px-3 pt-2 pb-3  no-border w-100 mb-0 mw730">
        <img src={plug} role="presentation" alt="" className="me-3" />
        <span className="bold f14 pb-0">
          Scegli l'elettrico e metti al sicuro il tuo futuro contro l'aumento
          del gas!
        </span>
      </p>

      {/* <Row>
        <div className='subtitle'>Benefici e investimenti totali</div>
        <div className='paragraph'>
          Se decidi di confermare questa soluzione otterrai i seguenti benefici
        </div>
      </Row> */}

      <Row className="same-height">
        <BoxInfo
          title="Benefici economici"
          icon={pig}
          subtitle="Ecco una stima del tuo <b>risparmio annuale totale</b> sulla bolletta, assumendo che i tuoi consumi rimangano invariati"
        >
          <div className="d-flex box-bottom justify-content-between">
            <div className="left">
              <div className="mb-8 bold f14">Spesa annua</div>
              <div className="d-flex justify-content-between">
                <div>
                  <div className="f12">Spesa Attuale</div>
                  <div className="money f16">
                    {data["SpesaAnnuaAttuale"]?.replaceAll(",", ".")}
                  </div>
                </div>
                <div>
                  <div className="f12">Spesa Futura minima</div>
                  <div className="money f16">
                    {data["SpesaAnnuaFutura"]?.replaceAll(",", ".")}
                  </div>
                </div>
              </div>
            </div>
            <div className="right align-content-between">
              <div className="mb-8 bold f14">Risparmio annuo fino a</div>
              <div>
                <div className="discount f12">
                  {Math.abs(data["RisparmioPerc"] * 100)?.toFixed(0)}%
                </div>
                <div className="money f16">
                  {Math.abs(risparmioAnnuo) + "€"}
                </div>
              </div>
            </div>
          </div>
        </BoxInfo>

        {data["CO2"] != 0 &&
          data["CO2"] != undefined &&
          data["CO2"] != null &&
          data["CO2"] != "0 kg" && (
            <BoxInfo
              title={"Benefici ambientali"}
              icon={world}
              subtitle="Ecco quanta produzione di <b>CO2</b> potresti evitare, riducendo il tuo impatto sul pianeta"
            >
              <div className="d-flex box-bottom justify-content-between">
                <div className="left">
                  <div className="d-flex justify-content-between">
                    <div>
                      <div className="f12">Emissioni CO2 evitata</div>
                      <div className="money f16">
                        {data["CO2"]?.replaceAll(",", ".")}
                      </div>
                    </div>
                    <div>
                      <div className="f12">Riduzione di</div>
                      <div className="money f16">
                        {risparmioAnnuo > 0
                          ? "0"
                          : Math.abs(data["RisparmioPerc"] * 100)?.toFixed(0)}
                        %
                      </div>
                    </div>
                  </div>
                </div>
                <div className="right align-content-between">
                  <div>
                    <div className="f12">Pari alla CO2 assorbita da</div>
                    <div className="money f16">{data["Alberi"]}</div>
                  </div>
                </div>
              </div>
              {/* <div className='d-flex box-bottom justify-content-end'>
            <div className='co2'>
              <div className='money f12'>{data["CO2"]?.replaceAll(",", ".")}/anno</div>
              <div className="alberi f16">Equivalenti alla CO2 assorbita da {data["Alberi"]} </div>
            </div>
          </div> */}
            </BoxInfo>
          )}

        {/* <BoxInfo
          title='Investimento'
          icon={money}
          subtitle={'Impegno economico'+ (Induzione ? "": "*") }
        >
           <div className='d-flex box-bottom h-100 align-items-end justify-content-end'>
            <div className='left'>
              <div className='mb-8'>Investimento</div>
                  <div className='money'>{data["Investimento"]}</div>
            </div>
            <div className='right align-content-between'>
              <div className='mb-8'>Recupero totale in</div>
              <div className='money'>{data["RecuperoTotale"] !== "Assetto scelto non conveniente" ? data["RecuperoTotale"] + " anni": "Assetto scelto non conveniente"}</div>
            </div>
          </div>
          </BoxInfo> */}
      </Row>

      <div className="blue-box px-3 pt-2 pb-3 my-3 no-border w-100 mw730 d-flex">
        <img src={forest} role="presentation" alt="" className="me-3" />
        <div>
          <span className="bold f14">
            {" "}
            <b>
              {" "}
              Scegli l'elettrico e metti al sicuro il tuo futuro contro
              l'aumento del gas!
            </b>
          </span>
          <p className="f14 pb-0 mb-3">
            Per saperne di più consulta il documento “2019 Refinement to the
            2006 IPCC Guidelines for National Greenhouse Gas Inventories” -
            Volume 4 “Agriculture, Forestry and Other Land Use”. Nella tabella
            8.2 del capitolo 8 “Settlements”, troverai le informazioni inerenti
            all’accumulo medio annuale di carbonio predefinito per albero nelle
            aree urbane, suddiviso per classe di specie.
          </p>
          <p className="mb-0 pb-0 ">
            {" "}
            <a
              className="f14 d-flex align-items-center "
              style={{ color: "#D3135A" }}
              href="https://www.ipcc-nggip.iges.or.jp/public/2019rf/pdf/4_Volume4/19R_V4_Ch04_Forest%20Land.pdf"
              aria-label="Apre il documento in una nuova scheda"
              target="_blank"
            >
              Consulta il documento{" "}
              <img
                src={chevronRight}
                alt=""
                role="presentation"
                aria-hidden={true}
              />{" "}
            </a>
          </p>
        </div>
      </div>

      {data["extraco2"] && data["extraco2"].length > 1 && (
        <p
          className="small-note my-3 f12"
          dangerouslySetInnerHTML={{ __html: data["extraco2"] }}
        ></p>
      )}
      <Row>
        <div className="spacer my-32"></div>
      </Row>
    </Container>
  );
}

export default BeneficiFinaliComponent;
