import { createSlice } from '@reduxjs/toolkit';
import formTemplate from "../utils/form"

const fields = formTemplate.pages.reduce((prev, curr) => [...curr.items, ...prev] , [])

const numerical = ["slider", "number" ]

const obj = {...fields.filter(x => x.cell).reduce((prev, curr) => {
  if (numerical.includes(curr.type)){
    prev[curr.cell] = curr.min ?? 0
  }
  else if (curr.type === "optional"){
    prev[curr.cell] = false
  }
  else if (curr.type === "bool"){
    prev[curr.cell] = false
  }
  else if (curr.type === "choice"){
    prev[curr.cell] = ""
  }
  else {
    prev[curr.cell] = ""
  }
  return prev
}, {})}

const testData = {
  C13: '20',
  C16: 1,
  C15: 1,
  C17: 12,
  C35: 50,
  C34: "Si",
  D61: 3,
  C61: "Si",
  C48Test: false,
  C78: "Non so",
  image: "landing",
}

const testDataEnelIT = {
  // "negozi": true,
  "products": [
      {
          "name": "Clima",
          "modello": "Comfee Clima 9 + 9 + 12 btu/h",
          "costo": "",
          "scontato": "1.999",
          "ecobonus": "",
          "link": "https://www.enelxstore.com/it/it/prodotti/climatizzatori/wall-air-conditioners/multi-split/comfee-9-9-12-btu-h-3fb-27k-cfw09a-x2-12a-by-midea",
          "qty": 1
      },
      {
          "name": "Lavatrice",
          "modello": "BESPOKE AI - EcoDosatore",
          "costo": "",
          "scontato": "1.000",
          "ecobonus": "",
          "link": null,
          "qty": 1
      },
      {
          "name": "Asciugatrice",
          "modello": "BESPOKE AI - Silent Dry",
          "costo": "",
          "scontato": "900",
          "ecobonus": "",
          "link": null,
          "qty": 1
      },
      {
          "name": "TV",
          "modello": "Crystal UHD 43\"",
          "costo": "",
          "scontato": "600",
          "ecobonus": "",
          "link": null,
          "qty": 1
      },
      {
          "name": "Fotovoltaico",
          "modello": "Enel SunStorage Black Large 4 KWp",
          "costo": "",
          "scontato": "13.990",
          "ecobonus": "",
          "link": null,
          "qty": 1
      },
      {
          "name": "Frigo",
          "modello": "Frigorifero Combinato F1rst 75 AI",
          "costo": "",
          "scontato": "1.200",
          "ecobonus": "",
          "link": null,
          "qty": 1
      }
  ],
  
  "ConsumoOrario": true,
  "EnelCoreModelId": 13926,
      "PreviousEnelCoreModelId": 0,
      "CreateDate": "2024-07-24T08:34:23.2862996+00:00",
      "ExecutionStart": null,
      "Email": null,
      "pageIndex":100,
      "ProjectUrl": "",
      "C10": "Non so",
      "C11": "Piccolo Condominio (2-8 abitazioni)",
      "C12": "",
      "C13": "150",
      "C14": "Milano",
      "C15": "2",
      "C16": "3",
      "C17": "12",
      "C18": "Si",
      "C19": "No",
      "C24": "No",
      "C25": "Metano",
      "F25": "",
      "F26": "0",
      "F27": "0",
      "C26": "Metano",
      "C27": "Metano",
      "C28": null,
      "C29": "No",
      "C30": null,
      "C31": "No",
      "C32": null,
      "C33": "",
      "C34": "No",
      "C35": "",
      "C36": "G",
      "C41": "Si",
      "C42": "1",
      "C45": "No",
      "C46": "1",
      "C47": null,
      "C48": "No",
      "C53": "No",
      "C54": "Si",
      "D61": "3",
      "C61": "No",
      "C62": null,
      "C66": null,
      "C67": null,
      "C68": null,
      "C69": null,
      "C70": null,
      "C71": null,
      "C78": "Non so",
      "C79": null,
      "C82": "6",
      "C84": "No",
      "D84": "",
      "D41": "LG ATMOSFERA Trialsplit 9+9+12 btu/h",
      "E41": null,
      "F41": null,
      "D46": null,
      "E46": null,
      "F46": null,
      "D48": "No",
      "D53": "Enel Sun Medium 3 kWp FORMIDABILE",
      "Risc_Attuale": "1924.43266193845",
      "Risc_Futura": "1895.42318969877",
      "Risc_Risparmio": "-30",
      "Cott_Attuale": "57.960120872383",
      "Cott_Futura": "0",
      "Cott_Risparmio": "-57.960120872383",
      "AC_Attuale": null,
      "AC_Futura": null,
      "TipologiaBalconi":"Muratura",
      "AC_Risparmio": null,
      "Foto_Risparmio": "-221",
      "Foto_Perc": "-0.3",
      "PresenzaFotovoltaico": "No",
      "PresenzaBalcone": "Si",
      "SpesaAnnuaAttuale": "2.568€",
      "SpesaAnnuaFutura": "1.267 €",
      "RisparmioAnnuo": "1301",
      "CO2": "2.003 kg",
      "Alberi": "111 alberi",
      "Investimento": "17.889 €",
      "RecuperoTotale": "+10",
      "RisparmioPerc": 0.50643169705163116,
      "PrestazionePennelli": "Top di gamma €€€",
      "PrestazioneRiscaldamento": "Più conveniente",
      "PrestazioneCottura": null,
      "NumeroClimatizzatori": "3",
      "CRMID": null,
      "extraco2": null,
      "Frigorifero": "Si",
      "Frigorifero_Risparmio": "47.69",
      "Frigorifero_Attuale": "63.34",
      "Frigorifero_Futura": "15.65",
      "Lavatrice": "Si",
      "Lavatrice_Risparmio": "11.43",
      "Lavatrice_Attuale": "22.85",
      "Lavatrice_Futura": "11.43",
      "Asciugatrice": "Si",
      "Asciugatrice_Risparmio": "69.55",
      "Asciugatrice_Attuale": "111.29",
      "Asciugatrice_Futura": "41.73",
      "TV": "Si",
      "TV_Risparmio": "32.29",
      "TV_Attuale": "86.20",
      "TV_Futura": "53.90",
      "User_TV": "Crystal UHD 43\"",
      "User_Frigo": "Frigorifero Combinato F1rst 75 AI",
      "Final_TV": "Si",
      "Final_Asciugatrice": "Si",
      "Final_Frigorifero": "Si",
      "Final_Lavatrice": "Si"
}

const testDataEnelFotoV = {
      "pageIndex":3,
      "ProjectUrl": "",
      "C10": "Non so",
      "C11": "Piccolo Condominio (2-8 abitazioni)",
      "C12": "",
      "C13": "150",
      "C14": "Milano",
      "C15": "2",
      "C16": "3",
      "C17": "12",
      "C18": "Si",
      "C19": "No",
      "C24": "No",
      "C25": "Metano",
      "F25": "",
      "F26": "0",
      "F27": "0",
      "C26": "Metano",
      "C27": "Metano",
      "C28": null,
      "C29": "No",
      "C30": null,
      "C31": "No",
      "C32": null,
      "C33": "",
      "C34": "No",
      "C35": "",
      "C36": "G",
      "C41": "Si",
      "C42": "1",
      "C45": "No",
      "C46": "1",
      "C47": null,
      "C48": "No",
      "C53": "No",
      "C54": "Si",
      "D61": "7",
      "C61": "No",
      "C62": null,
      "C66": null,
      "C67": null,
      "C68": null,
      "C69": null,
      "C70": null,
      "C71": null,
      "C78": "Non so",
      "C79": null,
      "C82": "4.5",
      "C84": "No",
      "D84": "",
      "D41": "LG ATMOSFERA Trialsplit 9+9+12 btu/h",
      "E41": null,
      "F41": null,
      "D46": null,
      "E46": null,
      "F46": null,
      "D48": "No",
      "D53": "Enel Sun Medium 3 kWp FORMIDABILE",
      "Risc_Attuale": "1924.43266193845",
      "Risc_Futura": "1895.42318969877",
      "Risc_Risparmio": "-30",
      "Cott_Attuale": "57.960120872383",
      "Cott_Futura": "0",
      "Cott_Risparmio": "-57.960120872383",
      "AC_Attuale": null,
      "AC_Futura": null,
      "AC_Risparmio": null,
      "Foto_Risparmio": "-221",
      "Foto_Perc": "-0.3",
      "PresenzaFotovoltaico": "No",
      "PresenzaBalcone": "No",
      "SpesaAnnuaAttuale": "2.568€",
      "SpesaAnnuaFutura": "1.267 €",
      "RisparmioAnnuo": "-1301",
      "CO2": "2.003 kg",
      "Alberi": "111 alberi",
      "Investimento": "17.889 €",
      "RecuperoTotale": "+10",
      "RisparmioPerc": 0.50643169705163116,
      "PrestazionePennelli": "Top di gamma €€€",
      "PrestazioneRiscaldamento": "Più conveniente",
      "PrestazioneCottura": null,
      "NumeroClimatizzatori": "3",
      "CRMID": null,
      "extraco2": null,
      "Frigorifero": "Si",
      "Frigorifero_Risparmio": "47.69",
      "Frigorifero_Attuale": "63.34",
      "Frigorifero_Futura": "15.65",
      "Lavatrice": "Si",
      "Lavatrice_Risparmio": "11.43",
      "Lavatrice_Attuale": "22.85",
      "Lavatrice_Futura": "11.43",
      "Asciugatrice": "Si",
      "Asciugatrice_Risparmio": "69.55",
      "Asciugatrice_Attuale": "111.29",
      "Asciugatrice_Futura": "41.73",
      "TV": "Si",
      "TV_Risparmio": "32.29",
      "TV_Attuale": "86.20",
      "TV_Futura": "53.90",
      "User_TV": "Crystal UHD 43\"",
      "User_Frigo": "Frigorifero Combinato F1rst 75 AI",
      "Final_TV": "Si",
      "Final_Asciugatrice": "Si",
      "Final_Frigorifero": "Si",
      "Final_Lavatrice": "Si"
}

const testDataApple = {
  D84: '',
  User_Foto: 'C53',
  PrestazionePennelli: 'Top di gamma €€€',
  User_Frigo: 'Frigorifero Combinato Samsung F1rst 75 AI',
  User_TV: 'Samsung Crystal UHD 65"',
  C46: '1',
  PrestazioneRiscaldamento: 'Top di gamma €€€',
  C31: 'No',
  C35: '50',
  C24: false,
  C25: 'Metano',
  C26: 'GPL',
  C27: 'Metano',
  C29: false,
  D61: '3',
  Indirizzo: '',
  C11: 'Monofamiliare',
  PresenzaFotovoltaico: 'No',
  PresenzaBalcone: 'No',
  TipologiaBalconi: '',
  RistrutturatoUltimi10Anni: false,
  C10: 'Prima del 1919 e non ristrutturata di recente',
  C36: 'F',
  C12: '',
  C13: '150',
  C16: '3',
  C15: '2',
  C19: false,
  CondizionatoriRiscaldamento: false,
  EV: '',
  C17: '12',
  C34: 'No',
  C61: 'No',
  C48Test: false,
  C78: 'Non so',
  image: 'landing',
  negozi: true,
  C14: 'Milano',
  screen: 1,
  Lavatrice: 'Si',
  Asciugatrice: 'Si',
  TV: 'Si',
  Frigorifero: 'Si',
  pageIndex: 100,
  F25: '0',
  F26: '0',
  C33: null,
  EnelCoreModelId: 22598,
  PreviousEnelCoreModelId: 0,
  CreateDate: '2024-12-25T18:14:30.3508+00:00',
  ExecutionStart: null,
  Email: null,
  ProjectUrl: 'https://greenovation.blob.core.windows.net/enelcore/22598.pdf',
  F27: '0',
  C30: null,
  C32: null,
  C41: false,
  C42: '1',
  C45: false,
  C47: null,
  C48: false,
  C53: false,
  C54: 'No',
  C62: null,
  C66: null,
  C67: null,
  C68: null,
  C69: null,
  C70: null,
  C71: null,
  C79: null,
  C82: '4',
  C84: 'No',
  D41: 'LG ATMOSFERA Trialsplit 9+9+12 btu/h',
  E41: null,
  F41: null,
  D46: null,
  E46: null,
  F46: null,
  D48: null,
  D53: 'Enel Sun Edition Small',
  Risc_Attuale: '2764.41540434771',
  Risc_Futura: '2713.35757065936',
  Risc_Risparmio: '-52',
  Cott_Attuale: '57.5919875905774',
  Cott_Futura: '82.004086394967',
  Cott_Risparmio: '24.4120988043897',
  AC_Attuale: null,
  AC_Futura: null,
  AC_Risparmio: null,
  Foto_Risparmio: '-194',
  Foto_Perc: '-0.186851377593254',
  SpesaAnnuaAttuale: '3.555€',
  SpesaAnnuaFutura: '1.796 €',
  RisparmioAnnuo: '-1759',
  CO2: '2.895 kg',
  Alberi: '161 alberi',
  Investimento: '19.640 €',
  RecuperoTotale: '+10',
  RisparmioPerc: 0.49478601714884796,
  PrestazioneCottura: null,
  NumeroClimatizzatori: '3',
  CRMID: null,
  extraco2: null,
  Frigorifero_Risparmio: '47,00',
  Frigorifero_Attuale: '63,00',
  Frigorifero_Futura: '16,00',
  Lavatrice_Risparmio: '12,00',
  Lavatrice_Attuale: '23,00',
  Lavatrice_Futura: '11,00',
  Asciugatrice_Risparmio: '69,00',
  Asciugatrice_Attuale: '111,00',
  Asciugatrice_Futura: '42,00',
  TV_Risparmio: '32,00',
  TV_Attuale: '86,00',
  TV_Futura: '54,00',
  Final_TV: 'Si',
  Final_Asciugatrice: 'Si',
  Final_Frigorifero: 'Si',
  Final_Lavatrice: 'Si',
  products: [
    {
      name: 'Clima',
      modello: 'Daikin Clima Siesta Trial Classic - 9000+9000+12000',
      costo: '',
      scontato: '3.827',
      ecobonus: '',
      link: 'https://www.enelxstore.com/it/it/prodotti/climatizzatori/wall-air-conditioners/multi-split/daikin-clima-siesta-dual-classic-9000-12000',
      qty: 1
    },
    {
      name: 'Lavatrice',
      modello: 'Lavatrice Samsung BESPOKE AI - EcoDosatore',
      costo: '',
      scontato: '960',
      ecobonus: '',
      link: null,
      qty: 1
    },
    {
      name: 'Asciugatrice',
      modello: 'Asciugatrice Samsung BESPOKE AI - Silent Dry',
      costo: '',
      scontato: '1.008',
      ecobonus: '',
      link: null,
      qty: 1
    },
    {
      name: 'Frigo',
      modello: 'Frigorifero Combinato Samsung F1rst 75 AI',
      costo: '',
      scontato: '1.992',
      ecobonus: '',
      link: null,
      qty: 1
    },
    {
      name: 'Fotovoltaico',
      modello: 'Enel Sun Black Xlarge',
      costo: '',
      scontato: '10.990',
      ecobonus: '',
      link: null,
      qty: 1
    },
    {
      name: 'TV',
      modello: 'Samsung Crystal UHD 65"',
      costo: '',
      scontato: '864',
      ecobonus: '',
      link: null,
      qty: 1
    }
  ]
}

const initialState = {
  ...obj,
  ...testData
  };

export const counterSlice = createSlice({
  name: 'data',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    set: (state, action) => {
      // console.log(action)
      if (action.payload.cell === 'ProjectUrl' && (!action.payload || !action.payload.value || action.payload.value === '')) 
          return;        
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes

      state[action.payload.cell] = action.payload.value;
      return state
    },
    reset: (state, action) => {
      // state = { ...testData, ProjectUrl: state.ProjectUrl, negozi: state.negozi }
      return state
    }
  },
});
export const { set, reset } = counterSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectCount = (state) => state.counter.value;
export const get = (state) => state.data

export default counterSlice.reducer;
