import React, {useState} from 'react'
import { Container, Row, Button } from 'react-bootstrap'

function Footer () {
    return <Container fluid className="footer">
        <div className="text-center">
            <h1 >QUESTO SARA' IL FOOTER UNA VOLTA DEFINITO</h1>
            <h2>Attualmente c'è solo un'immagine NON inspezionabile</h2>
        </div>
    </Container>
}

export default Footer;